// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.apexcharts-legend-series .apexcharts-legend-marker {
  left: -4px !important;
  top: 2px !important;
}

.bgblue{background-color: #1890ff;color: #fff;}
.css-1co9diy-MuiFormLabel-root-MuiInputLabel-root{line-height: normal;transform: translate(14px, 14px) scale(1);}`, "",{"version":3,"sources":["webpack://./src/assets/third-party/apex-chart.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA,QAAQ,yBAAyB,CAAC,WAAW,CAAC;AAC9C,kDAAkD,mBAAmB,CAAC,yCAAyC,CAAC","sourcesContent":[".apexcharts-legend-series .apexcharts-legend-marker {\n  left: -4px !important;\n  top: 2px !important;\n}\n\n.bgblue{background-color: #1890ff;color: #fff;}\n.css-1co9diy-MuiFormLabel-root-MuiInputLabel-root{line-height: normal;transform: translate(14px, 14px) scale(1);}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
