// api.js
import axios from 'axios';
import { getCookie, removeCookie } from 'utils/Cookie';
import CryptoJS from 'crypto-js';

const authData = getCookie('token');
const decrypted = CryptoJS.AES.decrypt(authData, 'P@ssw0rd').toString(CryptoJS.enc.Utf8);
const token = authData != "" ? JSON.parse(decrypted).token : '';
const pushTologin = () => {
  removeCookie('token');
  const baseUrl = process.env.REACT_APP_BASE_URL || '';
  window.location = `${baseUrl}/login`;
}
const axiosInstance = axios.create({
  withCredentials: true,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

const axiosInstanceDownload = axios.create({
  withCredentials: true,
  headers: {
    Authorization: `Bearer ${token}`,
    'content-type':`blob`
  },
  responseType: 'arraybuffer'
});

export const sendGetRequest = async (url, params = null) => {
  try {
    let response
    if(url.includes('/download') ? 
      response = await axiosInstanceDownload.get(url, { params })
      : 
      response = await axiosInstance.get(url, { params })
    )
    return response;
  } catch (error) {
    console.error(error);
    if (error?.response?.status === 403) {
      pushTologin()
    }
    throw error;
  }
};

export const sendPostRequest = async (url, data) => {
  try {
    const response = await axiosInstance.post(url, data);
    return response;
  } catch (error) {
    console.error(error);
    if (error?.response?.status === 403) {
      pushTologin()
    }
    throw error;
  }
};

export const sendPutRequest = async (url, data) => {
  try {
    const response = await axiosInstance.put(url, data);
    return response;
  } catch (error) {
    if (error?.response?.status === 403) {
      pushTologin()
    }
    throw error;
  }
};
