import { Button } from '../../../../../../node_modules/@mui/material/index';
import { getCookie, removeCookie } from 'utils/Cookie';
import CryptoJS from 'crypto-js';

const Profile = () => {
  const logout = () => {
    removeCookie('token')
    window.location.href = `${process.env.REACT_APP_BASE_URL}/login`
  };
  const authData = getCookie('token');
  const decrypted = CryptoJS.AES.decrypt(authData, 'P@ssw0rd').toString(CryptoJS.enc.Utf8);
  const UserName = authData != "" ? JSON.parse(decrypted).fullName : '';

  return (<>
    <div style={{ fontSize: '18px', fontWeight: '200', paddingRight: '11px', whiteSpace: 'nowrap' }}>{UserName}</div>
    <Button variant="contained" color="error" onClick={logout} style={{ padding: '3px 10px' }}>Logout</Button>
  </>
  );
};

export default Profile;
