// material-ui
import { Box, Typography } from '@mui/material';

// project import
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import { useEffect } from 'react';
import { getUserRole } from 'utils/getUserRole';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
  useEffect(() => {
    if ((getUserRole() == 'ROLE_ADMIN' || getUserRole() == 'ROLE_OPERATION'))
      menuItem.items[1].children.push({
        id: 'emloye',
        title: "Employee",
        type: 'item',
        url: '/employee'
      })
  }, [])
  const navGroups = menuItem.items.map((item) => {
    switch (item.type) {
      case 'group':
        return (<NavGroup key={item.id} item={item} />);
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });

  return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};

export default Navigation;
